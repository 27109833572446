<template>
	<div>
		<v-container>
			<v-row>
				<!-- Inicio Fundo/User -->
				<div class="col-md-12 col-sm-12">
					<v-card flat>
						<v-row style="margin: auto">
							<v-col cols="12">
								<s-crud
									:filter="filter"
									:config="config"
									close
									search-input
									no-full
									title="Ingreso de Temperaturas"
									formPermanent
									remove
									@close="close()"
									@save="save($event)"
									@rowSelected="rowSelected($event)"
									@getFullData="getFullData($event)"
									height="auto"
									noFooter
								>
									<template slot-scope="props">
										<v-container>
											<v-row>
												<v-col lg="5" md="5" cols="12">
													<v-row>
														<v-col cols="12" lg="12" md="12">
															<s-text
																label="Temperatura Pulpa"
																decimal
																v-model="props.item.CodTemperaturePulpe"
															></s-text>	
														</v-col>

														<v-col cols="12" lg="12" md="12">
															<s-text
																style="margin-top: -20px"
																label="Temperatura Ambiente"
																decimal
																v-model="props.item.CodTemperatureEmviroment"
															></s-text>
														</v-col>

														<v-col cols="6" lg="3" md="4" hidden>
															<s-text
																label="Hora"
																readonly
																v-model="props.item.CodHour"
															></s-text>
														</v-col>

														<v-col cols="6" lg="3" md="4" hidden>
															<s-text
																label="Fecha"
																readonly
																v-model="props.item.CodDate"
															></s-text>
														</v-col>
													</v-row>
												</v-col>

												<v-col lg="7" md="7" cols="12">
													<v-row>
														<v-col
															cols="12"
															lg="12"
															md="12"
														>
															<s-textarea
																label="Descripcion"
																v-model="props.item.CodObservations"
																:height="80"
															></s-textarea>
														</v-col>
													</v-row>
												</v-col>
											</v-row>	
										</v-container>
									</template>
								</s-crud>
							</v-col>
						</v-row>
					</v-card>
				</div>
			</v-row>
		</v-container>
	</div>
</template>

<script>
	import _sTemperaturePalletService from "../../../services/FreshProduction/TemperaturePalletSevice";
	import _sGenParamService from "../../../services/General/ParameterService";
	export default {
		props: {
			item: null,
			PltID: null
		},
		components: {},
		data: () => ({
			filter: { CocID: 0 },
			config: {
				service: _sTemperaturePalletService,
				model: {
					CodID: "ID",
					CodDate: "date",
					CodHour: "",
					CodTemperaturePulpe: 0,
					CodTemperatureEmviroment: 0
				},
				headers: [
					{
						text: "Temp. Pulpa",
						value: "CodTemperaturePulpe",
						width: 100
					},
					{
						text: "Temp. Ambiente",
						value: "CodTemperatureEmviroment",
						width: 100
					},
					{ text: "Observaciones", value: "CodObservations", width: 150 },
					{ text: "Hora", value: "CodHour", width: 50 },
					{ text: "Fecha", value: "CodDate", width: 50 }
				]
			},

			itemsAll: [],
			valParamPulpe: 0,
			valParamEmviroment: 0
		}),

		created() {
			console.log("itemsNew", this.item);
			this.filter.CocID = this.item.CocID;
			this.loadParameter();
		},

		methods: {
			loadParameter() {
				_sGenParamService
					.search(
						{ PrmName: "TemperaturePalletPulpe" },
						this.$fun.getUserID()
					)
					.then(resp => {
						if (resp.status == 200) {
							this.valParamPulpe = resp.data.PrmValue;
						}
					});

				_sGenParamService
					.search(
						{ PrmName: "TemperaturePalletEmviroment" },
						this.$fun.getUserID()
					)
					.then(resp => {
						if (resp.status == 200) {
							this.valParamEmviroment = resp.data.PrmValue;
						}
					});
			},

			close() {
				this.$emit("close");
			},

			rowSelected(val) {},

			isValidated(item) {
				var isValid = true;

				if (item.CodTemperaturePulpe.length == 0) {
					this.$fun.alert("Ingrese temperatura de Pulpa", "warning");
					isValid = false;
					return;
				}
				if (item.CodTemperatureEmviroment.length == 0) {
					this.$fun.alert("Ingrese temperatura de ambiente", "warning");
					isValid = false;
					return;
				}

				if (item.CodTemperaturePulpe > this.valParamPulpe) {
					this.$fun.alert(
						"Upps, La temperatura de pulpa maxima debe ser: " +
							this.valParamPulpe,
						"warning"
					);
					isValid = false;
					return;
				}

				if (item.CodTemperatureEmviroment > this.valParamEmviroment) {
					this.$fun.alert(
						"Upps, La temperatura de ambiente maxima debe ser: " +
							this.valParamEmviroment,
						"warning"
					);
					isValid = false;
					return;
				}

				return isValid;
			},

			save(val) {
				if (!this.isValidated(val)) {
					return;
				}

				val.CocID = this.item.CocID;
				val.PltID = this.item.PltID;
				val.CodHour = this.$fun.getHour();
				val.CodDate = this.$fun.getDate();
				val.SecStatus = 1;
				val.UsrCreateID = this.$fun.getUserID();

				val.save();
			},

			getFullData(val) {
				this.itemsAll = val.data;
			}
		}
	};
</script>

<style></style>
