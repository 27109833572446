<template>
	<v-card>
		<v-container>
			<v-row>
				<div class="col-md-12 col-sm-12">
					<v-toolbar dense>
						<v-toolbar-title>
							<v-row>
								<v-col cols="8">
									<v-chip
										style="margin: 0px"
										small
										:color="'info'"
									>
										{{ "Toma de Temperatura - Codigo QR" }}
									</v-chip>
								</v-col>
								<v-col cols="4">
									<v-switch
										label="Recoger"
										v-model="pick"
									></v-switch>
								</v-col>
							</v-row>
						</v-toolbar-title>
					</v-toolbar>
					<v-card dense>
						<v-row justify="center" style="margin:auto">
							<v-col align="center" cols="12" lg="10" md="10">
								<s-scanner-qr
									:config="configScann"
									autofocus
									return-object
									@onValue="onValue($event)"
									:readonly="false"
								></s-scanner-qr>
							</v-col>
						</v-row>
					</v-card>
				</div>
			</v-row>
		</v-container>

		<v-dialog
			v-if="openDialog"
			v-model="openDialog"
			scrollable
			width="unset"
			persistent
			fullscreen
			transition="dialog-bottom-transition"
		>
			<!-- <temperature-new @close="close()" :item="itemAll"> </temperature-new> -->
			<temperature-new @close="close()" :item="itemAll">
			</temperature-new>
		</v-dialog>
	</v-card>
</template>

<script>
	import _sPrfCoolingChamber from "../../../services/FreshProduction/PrfCoolingChamberService";
	import _sPrfChamberService from "@/services/FreshProduction/PrfChamberService";
	import TemperatureNew from "./TemperatureNew.vue";

	export default {
		components: { TemperatureNew },
		data() {
			return {
				itemAll: {},
				pick: false,
				openDialog: false,
				configScann: {},
				PltID: 0
			};
		},

		methods: {
			getPalletInCamera(PltID) {
				_sPrfCoolingChamber
					.getPalletInCamera({ PltID: PltID }, this.$fun.getUserID())
					.then(resp => {
						if (resp.status == 200) {
							this.itemAll = resp.data;
							this.itemAll.CocStatus = 2;
						}
					});
			},

			onValue(val) {
				this.PltID = val;

				if (!this.pick) {
					_sPrfCoolingChamber
						.getPalletInCamera(
							{ PltID: this.PltID },
							this.$fun.getUserID()
						)
						.then(resp => {
							if (resp.status == 200) {
								this.itemAll = resp.data;

								if (resp.data == null) {
									this.$fun.alert(
										"No se encontraron registros",
										"warning"
									);
									return;
								} else {
									this.openDialog = true;
								}
							}
						});
				} else {
					this.$fun
						.alert(
							"Seguro de recoger Pallet ==> " + this.PltID,
							"question"
						)
						.then(resp => {
							if (resp.value) {
								_sPrfCoolingChamber
									.getPalletInCamera({ PltID: this.PltID }, this.$fun.getUserID())
									.then(resp => {
										if (resp.status == 200) {
											if (resp.data == null) {
												
												this.$fun.alert("No se encontraron registros","warning");
												return;

											} else {
												this.itemAll = resp.data;
												this.itemAll.CocStatus =  2

												console.log("guardar", this.itemAll);

												_sPrfCoolingChamber
													.save(this.itemAll, this.$fun.getUserID()).then(resp => {
														if (resp.status == 200) {
															this.$fun.alert(
																"Pallet Recogido Correctamente",
																"success"
															);
														}
													});
											}
										}
									});
							}
						});
				}
			},

			close() {
				this.openDialog = false;
			}
		}
	};
</script>
