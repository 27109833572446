<template v-slot:no-data>
	<v-card dense>
		<v-row style="margin-top: 4px;">
			<v-col cols="12" lg="12" md="12">
				<v-tabs v-model="currentItem">
					<v-tab href="#QR"> QR</v-tab>
					<v-tab href="#Manual"> Manual</v-tab>
					
				</v-tabs>

				<v-tabs-items v-model="currentItem">
					<v-tab-item :value="'Manual'">
						<temperature-main />
					</v-tab-item>

					<v-tab-item :value="'QR'">
						<temperature-pallet-qr />
					</v-tab-item>
				</v-tabs-items>
			</v-col>
		</v-row>
	</v-card>
</template>
<script>
	//Component
	import TemperatureMain from "./TemperatureHandbook.vue"
	import TemperaturePalletQr from "./TemperaturePalletQr.vue"
	export default {
		components: {TemperatureMain, TemperaturePalletQr },
		props: {},
		data: () => ({
			currentItem: "tab-Funciones",
		}),
	};
</script>
