<template>
	<v-card>
		<v-container>
			<v-row justify="center">
				<v-col cols="4" lg="3" md="3">
					<s-switch
						label="En Camara"
						@input="inputInCamera($event)"
					></s-switch>
				</v-col>

				<v-col cols="6" lg="3" md="3" >
					<s-select
						label="Camara"
						:items="itemChambers"
						item-text="ChaDescription"
						item-value="ChaID"
						return-object
						v-model="objChamber"
					/>
				</v-col>

				<v-col cols="6" lg="3" md="3" >
					<s-date
						label="Fecha Inicio"
						v-model="filter.cDateInitial"
					></s-date>
				</v-col>
				<v-col cols="6" lg="3" md="3" >
					<s-date label="Fecha Fin" v-model="filter.cDateFin"></s-date>
				</v-col>
			</v-row>
			<v-row>
				<div class="col-md-12 col-sm-12">
					<v-toolbar dense>
						<v-toolbar-title
							>En Cámara
							<v-chip style="margin: 0px" small :color="'info'">
								{{ " - " + InCameraText }}
							</v-chip>
						</v-toolbar-title>
					</v-toolbar>
					<v-card dense>
						<v-row justify="center">
							<!-- <v-col
								align="center"
								cols="12"
								lg="10"
								md="10"
								v-if="InCamera == 1"
							>
								<s-scanner-qr
									:config="configScann"
									autofocus
									return-object
									@onValue="onValue($event)"
									@onData="onData($event)"
								></s-scanner-qr>
							</v-col> -->
							<v-col
								cols="4"
								lg="2"
								md="4"
								v-for="item in palletsInside"
								:key="item.CocID"
							
							>
								<v-card height="120" max-width="110">
									<v-card-text class="justify-center">
										<b>{{ "PAL - " + item.PltID }}</b
										><br />
										<b>{{
											objChamber.TypeFreshProductionDestinationText
										}}</b>
									</v-card-text>
									<v-card-actions class="justify-center">
										<v-btn
											v-if="InCamera == 1"
											rounded
											color="info"
											@click="clickNew(item)"
										>
											<i style='font-size: 22px;' class="fas fa-temperature-low"></i>
										</v-btn>
										<v-btn
											v-else
											rounded
											color="warning"
											@click="clickReturnCamera(item)"
										>
											<v-icon>
												fas fa-undo-alt
											</v-icon>
										</v-btn>
									</v-card-actions>
								</v-card>
							</v-col>
						</v-row>
					</v-card>
				</div>
			</v-row>
		</v-container>

		<v-dialog
			v-if="openDialog"
			v-model="openDialog"
			scrollable
			width="unset"
			persistent
			fullscreen
			transition="dialog-bottom-transition"
		>
			<temperature-new @close="close()" :item="itemAll"> </temperature-new>
		</v-dialog>
	</v-card>
</template>

<script>
	import _sPrfCoolingChamber from "../../../services/FreshProduction/PrfCoolingChamberService";
	import _sPrfChamberService from "@/services/FreshProduction/PrfChamberService";
	import TemperatureNew from "./TemperatureNew.vue";

	export default {
		components: { TemperatureNew },
		data() {
			return {
				itemChambers: [],
				palletsInside: [],
				InCamera: 0,
				InCameraText: "",
				openDialog: false,
				filter: { TypeDestiny: "UE" },
				objTypeDestiny: null,
				objChamber: null,
				itemAll: {},
				CocIDOLD: 0,
				
				
				configScann: {},
				PltID: 0,
			};
		},

		created() {
			this.loadData();
			this.loadCamere();
			this.InCamera = 1;
		},

		watch: {
			"filter.cDateInitial"() {
				this.loadData();
			},
			"filter.cDateFin"() {
				this.loadData();
			},

			objChamber() {
				this.filter.ChaID = this.objChamber.ChaID;
				console.log("objChambers", this.objChamber);
				this.loadData();
			},

			PltID(){
				this.goToChamber();
			},
		},

		methods: {

			/* onValue(val) {
				console.log("onValue",val);
				this.PltID = val
			},

			onData(val) {
				console.log("onData", val);
			}, */

			close() {
				this.openDialog = false;
			},

			loadData() {
				_sPrfCoolingChamber
					.list(this.filter, this.$fun.getUserID())
					.then(resp => {
						if (resp.status == 200) {
							this.palletsInside = resp.data;
						}
					});
			},

			loadCamere() {
				_sPrfChamberService.list(this.$fun.getUserID()).then(resp => {
					if (resp.status == 200) {
						this.itemChambers = resp.data;
						this.objChamber = this.itemChambers[0];
					}
				});
			},

			inputInCamera(val) {
				this.filter.InCamera = val;
				this.loadData();
				this.InCamera = val;

				if (val == 0) {
					this.InCameraText = "Pallet Recogidos";
				} else {
					this.InCameraText = "Pallet por Ingresar Temperaturas";
				}
			},

			clickNew(item) {
				console.log(item);
				this.itemAll = item;
				this.openDialog = true;
			},

			clickReturnCamera(item) {
				this.CocIDOLD = item.CocID; //Id Anterior
				
				item.CocIDOLD = this.CocIDOLD;
				item.CocID = 0;
				item.CocStatus = 1;
				

				this.$fun
					.alert("Esta seguro de retornar Pallet a Camara ?", "question")
					.then(val => {
						if (val.value) {
							_sPrfCoolingChamber
								.save(item, this.$fun.getUserID())
								.then(r => {
									if (r.status == 200) {
										this.$fun.alert(
											"Operacion realizada con exito",
											"success",
										);
										this.loadData();
									}
								});
						}
					});

				console.log("clickReturnCamera", item);
			},
		},
	};
</script>
